<template>
  <div class="fill-personal-info">
    <div class="title">请按照以下指引完成账号实名信息补充</div>
    <div class="steps">
      <RealNameStep :active="1" />
    </div>
    <div class="form-content">
      <div class="account">
        您的账号
        <div class="account-number">
          {{ ellipsisPhone }}
          <span class="security">
            <img :src="require('assets/images/real-security.png')" alt="" />
            个人账号
          </span>
        </div>
      </div>
      <div class="input-wrap">
        <div class="title must">手机号码</div>
        <div class="input-inner">
          <input
            v-model="phoneNumber"
            maxlength="11"
            @input="phoneNumber = phoneNumber.replace(/[^0-9]/g, '')"
            type="text"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div class="input-wrap">
        <div class="title must">手机验证码</div>
        <div class="input-inner">
          <input v-model="phoneVerif" type="text" placeholder="请输入验证码" />
          <div class="verif">
            <span class="txt">
              <span v-if="verif_seconds == 60" @click="sendVerif"
                >获取验证码</span
              >
              <span v-if="verif_seconds < 60">{{ verif_seconds }}s</span>
            </span>
          </div>
        </div>
      </div>
      <div class="next" @click="nextStep">下一步</div>
    </div>
  </div>
</template>

<script>
import RealNameStep from "components/copyright-service/real-name/RealNameStep";
import { getMobileAchCaptchas, checkMobileAchCaptchas } from "api/common";
export default {
  name: "FillPersonalInfo",
  data() {
    return {
      currentMobile: this.$store.state.user_info
        ? this.$store.state.user_info.mobile
        : "",
      phoneNumber: "",
      phoneVerif: "",
      verif_code: null,
      verif_seconds: 60,
      verif_timer: null,
      ellipsisPhone: "", //省略的手机号
    };
  },
  methods: {
    //发送验证码计时
    setVerifTime() {
      this.verif_timer = setInterval(() => {
        if (this.verif_seconds > 0) {
          this.verif_seconds--;
        } else {
          clearInterval(this.verif_timer);
          this.verif_seconds = 60;
        }
      }, 1000);
    },
    //发送验证码
    sendVerif() {
      if (!this.phoneNumber) {
        return this.$message({
          type: "error",
          message: "请输入手机号码!",
        });
      }
      if (this.currentMobile != this.phoneNumber) {
        return this.$message({
          type: "error",
          message: "输入的手机号与绑定的手机号不符!",
        });
      }
      const data = {
        type: 5,
        mobile: this.phoneNumber,
      };
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.verif_code = res.data.codeId;
          this.setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //下一步
    nextStep() {
      if (!this.phoneNumber) {
        return this.$message({
          type: "error",
          message: "请输入手机号码!",
        });
      }
      if (!/^0{0,1}1[3|4|5|6|7|8|9]\d{9}$/.test(this.phoneNumber)) {
        return this.$message({
          type: "error",
          message: "请输入正确的手机号码!",
        });
      }
      console.log(this.phoneNumber);
      if (this.phoneNumber !== this.currentMobile) {
        return this.$message({
          type: "error",
          message: "请输入账号绑定的手机号码",
        });
      }
      if (this.verif_code == "") {
        return this.$message({
          type: "error",
          message: "请获取验证码!",
        });
      }
      if (!this.phoneVerif) {
        return this.$message({
          type: "error",
          message: "请输入验证码!",
        });
      }
      const params = {
        mobile: this.phoneNumber,
        codeId: this.verif_code,
        code: this.phoneVerif,
      };
      checkMobileAchCaptchas(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "验证成功",
          });
          this.$router.push({
            path: "/copyrightService/realName/supplementInfo",
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
  },
  created() {
    this.ellipsisPhone = "" + this.currentMobile;
    this.ellipsisPhone =
      this.currentMobile.substr(0, 3) + "****" + this.currentMobile.substr(7);
  },
  components: {
    RealNameStep,
  },
};
</script>

<style lang="scss" scoped>
.fill-personal-info {
  .title {
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .steps {
    margin: 39px 0 60px;
  }
  .form-content {
    width: 300px;
    margin: 0 auto;
    .account {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      &-number {
        @include flex-start;
        margin-top: 10px;
        .security {
          @include flex-center;
          width: 75px;
          height: 23px;
          margin-left: 6px;
          background: #f5f9ff;
          border: 1px solid #ff6900;
          font-size: 12px;
          font-weight: 500;
          color: #ff6900;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    .input-wrap {
      @include flex-center(column);
      justify-content: normal;
      align-items: flex-start;
      margin-top: 30px;
      .title {
        position: relative;
        padding-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        &.must::before {
          content: "*";
          position: absolute;
          top: 3px;
          left: 2px;
          font-size: 14px;
          color: #eb441e;
        }
      }
      .input-inner {
        position: relative;
        width: 100%;
        margin-top: 14px;
        input {
          width: 100%;
          height: 45px;
          padding: 10px 15px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          border: 1px solid #eceff1;
          border-radius: 5px;
          box-sizing: border-box;
          transition: border-color 0.3s;
          &:focus {
            border-color: #ff6900;
          }
        }
        .verif {
          @include flex-center;
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 100%;
          font-size: 12px;
          font-weight: 500;
          color: #ff6900;
          &::before {
            content: "";
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            width: 1px;
            height: 20px;
            background-color: #d9dee6;
          }
          .txt {
            @include noselect;
            cursor: pointer;
          }
        }
      }
    }
    .next {
      @include noselect;
      height: 40px;
      margin-top: 30px;
      background: #ff6900;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
